import React, {useState}  from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/faq.sass"
import "../assets/wave.sass"

// const [dropDown, setDropDown] = useState({
//   height:"auto"}
// );  

// const [modal, setModal] = useState(false);
// const toggleModal = () => setModal(previousValue => !previousValue);

// const dropdownOptions = modal ? 'show_recepie' : '';

const Faq = () =>(
    <div className="faq-component">

      <SEO title="X signature FAQ" />
      <div className="wave">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
          d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
          opacity=".25"
        />
          <path
            d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
            opacity=".5"
          />
          <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
        </svg>
      </div>

      <div className="faq-header">
        <h1>FAQ</h1>
        <h3>
          Got Questions?
        </h3>

      </div>

      <div className="faq-container">
        <div className="faq">
          <p className="faq-q">Q</p>

            <h2>WHAT IS THE MOST COMMON REASON TO HAVE A SEWER BACKUP</h2>
            <div className="faq-answer">
              <p>
                <span className="bold-text">Tree roots intrusion</span>, roots will grow into joints and create a nest inside the pipe and catch toilet paper, human feces that will eventually create a sewer backup.
              </p>
            </div>
        </div>

        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHY IS IT IMPORTANT TO HAVE THE SEWER LINE SCOPED/INSPECTED?</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              To check the conditions of the sewer line. A sewer line scoping will reveal its current condition and can help you identify potential issues to avoid costly surprises in the future.
            </p>
          </div>
        </div>

        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>OTHER REASONS WHY YOU HAVE SEWER BACKUPS</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              Flushing undissolvable items down the toilet like <span className="bold-text">baby wipes, femal e products, or paper towels</span>. These items can eventually cause a backup when they begin to accumulate and catch other debri.
            </p>
          </div>
        </div>

        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHEN SHOULD POWER RODDING BE DONE?</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              When you have a backup. Slow draining water is a symptoms that you might have blockage due to <span className="bold-text">tree roots, build up, or misalignments</span>
            </p>
          </div>
        </div>

        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHEN IS THE HYDROJETTING USED?</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              Hydrojetting is used when there is large amounts of roots or sludge in the line, for example accumulation of <span className="bold-text">grease, human feces, and toilet paper</span>. This method is used when the power rodding does not remove the clog.
            </p>
          </div>
        </div>
        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHY IS THERE WATER IN THE FLOOR DRAIN</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              There is a <span className="bold-text">P-trap</span> underneath the floor in a <span className="bold-text">U</span> shape that holds the water, the purpose of the water inside the P-trap is to prevent sewer gases from coming up. If you smell sewer gases, check your floor drains for water. If they are empty simply add water.
            </p>
          </div>
        </div>
        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHY DO I HAVE SEWER SMELL IN MY HOUSE</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              <span className="bold-text">Your P-traps is dry</span> or an exposed pipe with no P-trap or no cap on.  With no water in the P-trap, there is nothing to hold the sewer gases. Simply add water.
            </p>
          </div>
        </div>
        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>WHY IS THERE A FLOOR DRAIN  </h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>
              The purse of the floor drains in a home or a commercial building is to carry the overflow of water coming from heavy rain or a broken pipe.
            </p>
          </div>
        </div>
        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>MOST COMMON REASON FOR A KITCHEN SINK BACKUP</h2>
          <div className={`faq-answer faq-answer{}`}>
            <ul>
            <li>
              Improper use of <span className="bold-text">Garbage Disposal</span>. People mistakenly use garbage disposals as garbage cans, constent disposal of leftovers can created a clog dipper down the line.
            </li>
            <li>
            <span className="bold-text">Dumping grease</span> into the sink. Over time grease builds up along the inner walls of the pipe creating a narrower line until it eventually closes and stops the flow of the water.
            </li>
            </ul>

          </div>
        </div>
        <div className="faq">
          <p className="faq-q">Q</p>

          <h2>HOW OFTEN SHOULD I DO MAINTENANCE IN MY SEWER LINE?</h2>
          <div className={`faq-answer faq-answer{}`}>
            <p>It all depends on the history of the sewer backups and the reason why.
              When you have a history of tree roots in your line, you should do a yearly power rodding to cut the roots and that will prevent nasty backup.</p>
          </div>
        </div>

      </div>

    </div>
  )

export default Faq
